










































































































import './scss/TaskDetailsInfo.scss';
import './scss/TaskDetailsInfoAdaptive.scss';
import { formatDate } from '@utility/formatDate';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectTasksActions } from "@store/modules/project-tasks/Types";
import { ITask } from '@store/modules/project-tasks/interfaces/Interfaces';
import { IAddTaskPostData } from "@store/modules/project-tasks/interfaces/ApiRequests";

const NSProject = namespace('storeProject');
const NSProjectTasks = namespace('storeProjectTasks');
const NSUser = namespace('storeUser');

@Component({
    name: 'TaskDetailsInfo',

    components: {
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        BaseCheckbox: () => import('@components/BaseCheckbox'),
        TaskRelatedSubject: () => import('@components/TaskRelatedSubject/TaskRelatedSubject.vue'),
    },
})
export default class TaskDetailsInfo extends Vue {
    @Prop({ default: false }) task!: ITask;
    @Prop({ default: false }) onlyInfo!: boolean;
    @Prop({ default: false }) executedByUser!: boolean;
    @Prop({ default: false }) waitingSaveSubtask!: boolean;

    @NSProject.Getter('projectId') projectId!: number;
    @NSProjectTasks.Action(ProjectTasksActions.A_CHECK_TASK) checkTask!: (payload) => Promise<void>
    @NSProjectTasks.Action(ProjectTasksActions.A_COMPLETE_TASK) sendCompleteTaskFormAction!: (payload) => Promise<void>;

    timeForResetSubtaskTitleField = 500;
    timeFromCompleteToCheckTask = 1000;
    newSubtaskTitle = '';
    files: File[] = [];

    get executorFullName() {
        return this.task.executor?.fullName ?? '';
    }

    get subtasks() {
        return this.task.subtasks || [];
    }

    get taskComment() {
        if (this.task && this.task.comment && this.task.comment.indexOf('\n') !== -1) {
            return `<p>${this.task.comment.replaceAll('\n', '</p><p>')}</p>`;
        }
        return this.task.comment;
    }

    get dateToday() {
        return formatDate({ date: new Date(), format: 'yyyy-mm-dd' });
    }

    emitAddSubtask() {
        this.$emit('add-subtask', this.formAddingSubtaskPostData());
        setTimeout(() => {
            this.newSubtaskTitle = '';
        }, this.timeForResetSubtaskTitleField);
    }

    emitDeleteSubtask(subtask) {
        this.$emit('delete-subtask', subtask);
    }

    onChangeSubtaskCheckbox(item) {
        const dataPerformTask = {
            appId: this.projectId,
            id: item.uuid,
        }
        const dataCheckTask = {
            appId: this.projectId,
            id: item.uuid,
            checkDate: formatDate({ date: new Date(), format: 'yyyy-mm-ddThh:mm:ss' }),
        }
        this.sendCompleteTaskFormAction({ params: dataPerformTask, type: 'subtask' }).then(() => {
            setTimeout(() => {
                this.checkTask(dataCheckTask).then(() => {
                    this.changeSubtaskState(item.uuid);
                });
            }, this.timeFromCompleteToCheckTask);
        });
    }

    changeSubtaskState(subtaskId) {
        this.subtasks.forEach((subtask) => {
            if (subtask.uuid === subtaskId) {
                subtask.state.name = 'Выполнено';
            }
        })
    }

    formAddingSubtaskPostData(): IAddTaskPostData {
        return {
            appId: this.projectId,
            userIds: this.task.executor?.uuid ? [this.task.executor.uuid] : [],
            name: this.newSubtaskTitle,
            text: this.task.description,
            deadline: this.task.deadline ?? this.dateToday,
            taskId: this.task.uuid,
        }
    }
}
